import { useBlocNext } from "@blac/react";
import styled from "@emotion/styled";
import type { FC } from "react";
import React, { useEffect } from "react";
import useUserAuthStage from "src/state/hooks/useUserAuthStage";
import FadeWrap from "src/ui/components/FadeWrap/FadeWrap";
import LoginForm from "src/ui/components/LoginForm/LoginForm";
import ResetPasswordForm from "src/ui/components/ResetPasswordForm/ResetPasswordForm";
import type { SignupCustomStepProps } from "src/ui/components/SignupCustomContent/RenderSignupCustomStep";
import { SignupCustomStepType } from "src/ui/components/SignupCustomContent/SignupCustom.types";
import SignupCustomBloc from "src/ui/components/SignupCustomContent/state/SignupCustomBloc";
import RegisterForm from "../../RegisterForm/RegisterForm";

const PositionHelper = styled.div`
  label: PositionHelper;
  position: relative;
  padding: 2rem var(--space-md);
  height: 100%;

  div[class*="SignupWrapper"] {
    height: auto;
    min-height: auto;
  }
`;

const SignupCustomStepAuthenticationContent: FC<SignupCustomStepProps> = ({
  step,
  onStepCompleted
}) => {
  const authStage = useUserAuthStage();
  const [, { campaignFunnelKey }] = useBlocNext(SignupCustomBloc);
  const [view, setView] = React.useState<"login" | "register" | "reset-pw">(
    "register"
  );

  useEffect(() => {
    if (authStage === "fullAuth") {
      void onStepCompleted(step);
    }
  }, [authStage]);

  const handleSuccess = async () => onStepCompleted(step);

  return (
    <FadeWrap>
      <PositionHelper>
        <nine-center>
          {view === "register" && (
            <RegisterForm
              loginLink={() => setView("login")}
              scopeKey={campaignFunnelKey}
              onSuccess={handleSuccess}
            />
          )}
          {view === "login" && (
            <LoginForm
              registerLink={() => setView("register")}
              resetPasswordLink={() => setView("reset-pw")}
              onSuccess={handleSuccess}
            />
          )}
          {view === "reset-pw" && (
            <ResetPasswordForm
              loginLink={() => setView("login")}
              onSuccess={handleSuccess}
            />
          )}
        </nine-center>
      </PositionHelper>
    </FadeWrap>
  );
};

const SignupCustomStepAuthentication: FC<SignupCustomStepProps> = (props) => {
  const isAuthPage = props.step.type === SignupCustomStepType.Authentication;
  return isAuthPage ? (
    <SignupCustomStepAuthenticationContent
      onStepCompleted={props.onStepCompleted}
      step={props.step}
    />
  ) : (
    <></>
  );
};

export default SignupCustomStepAuthentication;
